/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  margin: 0;
  font-family:'Montserrat',sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #FF0000;
}
a{
  color: inherit;
  text-decoration: none !important;
}
a:hover{
  color: inherit;
  text-decoration: none !important;
}
a::after{
  color: inherit;
  text-decoration: none !important;
}
a::before{
  color: inherit;
  text-decoration: none !important;
}
a:focus{
  color: inherit;
  text-decoration: none !important;
}
.slick-dots li button:before{
  font-size: 15.2px !important;
  line-height: 15.2px !important;
  color: #c4c4c4 !important;
}
.slick-dots li.slick-active button:before{
  color: #33bc82 !important;
}
.member .member-img {
  position: relative;
  overflow: hidden;
}
.member .social {
  position: absolute;
  left: 0;
  bottom:-50px;
  right: 0;
  height: 50px;
  opacity: 0;
  transition: bottom ease-in-out 0.4s;
  text-align: center;
  background: rgba(30, 67, 86, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 14.9px 14.9px;
  color: #FFF;
}
.member:hover .social {
  bottom:0;
  opacity: 1;
  transition: bottom ease-in-out 0.4s;
}
.member .social a{
  text-decoration: none !important;
  padding: 4px;
  color: inherit;
}
.member .social a:hover{
 color: #33bc82;
}
.css-sm407l-MuiButtonBase-root-MuiTab-root.Mui-selected{
  color:"#33bc82 !important"
}

.ReactModal__Overlay {
  z-index: 1200 !important;
}